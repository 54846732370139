var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"font-title margin"},[_vm._v("供应商店铺")]),_c('p',{staticClass:"margin"},[_c('a-input-search',{staticStyle:{"width":"500px"},attrs:{"enter-button":"","placeholder":"请输入店铺名称、证件号"},on:{"search":function($event){_vm.form.pageNum=1;_vm.getList()}},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}})],1),_c('a-table',{attrs:{"data-source":_vm.data,"columns":_vm.storeColumns,"loading":_vm.loading,"rowKey":function (record,index){return index},"size":"middle","pagination":{
    size: 'default',
    total: _vm.total,
    current: _vm.form.pageNum,
    pageSize: _vm.form.pageSize,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '50', '100'],
    showTotal: function (total) { return ("共有 " + total + " 条数据"); },
    showQuickJumper: true,
    onShowSizeChange: function (current, pageSize) {_vm.form.pageSize = pageSize; _vm.form.pageNum = 1},
    onChange: function (pageNum) { return _vm.form.pageNum = pageNum; } }},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"supplierName",fn:function(text,row){return [_c('router-link',{attrs:{"to":("/shop/s" + (row.id))}},[_vm._v(_vm._s(text))])]}},{key:"remainingSaleAmount",fn:function(text){return [_c('span',{staticClass:"font-error"},[_vm._v(_vm._s(text))])]}},{key:"action",fn:function(text,record){return [(record.supplierType !== '个人卖家')?_c('a-popconfirm',{attrs:{"cancelText":"取消","okText":"确定","title":"是否下载经营证明?"},on:{"confirm":function () { return _vm.downLoad(record); }}},[_c('a',[_vm._v("经营证明")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }